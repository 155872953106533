html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  background-size: 100%;
  background-position: bottom;
  background-color: #242424;
  min-height: 100%;
}
form {
  padding: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.nopadding {
  padding: 0px;
  margin-top: 0px;
}

form button,
form h5 {
  margin: 20x 0;
}
